import React, { useState, useEffect, useRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { update_alarms, list_alarms_by_sector, note_by_id_alarms } from "../../services/alarms";
import { Checkbox } from 'primereact/checkbox';
import ConfirmModal from '../../components/Modals/ConfirmModal/index';
import { Dropdown } from 'react-bootstrap';
import {
    AlarmIcon,
    ContainerCardOpt,
    AlarmCardIconReturn,
    AlarmLabelCheckBox,
    AlarmCardIconFile,
    ContainerMsgNoAlarm,
    CustomDropdown,
    CustomToggle,
    CustomMenu,
    CustomlistBox
} from './styles'

import AlarmCardList from './AlarmCardList';
import AlarmDialogList from './AlarmDialogList';

import { FaBell } from "react-icons/fa6";
import useUserStore from '../../store/useUserStore';
import moment from 'moment/moment';
import useCompanySectorStore from '../../store/useCompanySectorStore';

export default function AlarmList({ data, token }) {
    const intl = useIntl();
    const toast = useRef(null);
    const { user } = useUserStore();
    const { sectorIdSelected, clientIdSelected } = useCompanySectorStore();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [alarms, setAlarms] = useState(null);
    const [selectedAlarms, setSelectedAlarms] = useState(null);
    const [visible, setVisible] = useState(false);
    const [finalList, setFinalList] = useState(false);
    const [cleanFilter, setCleanFilter] = useState(false);
    const [seeCheck, setSeeCheck] = useState(true);
    const [unseeCheck, setUnseeCheck] = useState(true);
    const [data_alarms, setDataAlarms] = useState(null);
    const [selectedSeeCheck, setSelectedSeeCheck] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [confirmAddBack, setConfirmAddBack] = useState(false);
    const [removed, setRemoved] = useState(null);
    const [addBack, setAddBack] = useState(null);
    const [colorAlarm, setColorAlarm] = useState(null);
    const [obs, setObs] = useState('');
    const [obsTemp, setObsTemp] = useState(null);
    const [oldObs, setOldObs] = useState(null);
    const [msgModal, setMsgModal] = useState('');
    const [showMsgModal, setShowMsgModal] = useState(false);
    const [showCondition, setShowCondition] = useState(false);
    const [saveMsg, setSaveMsg] = useState(false);

    useEffect(() => {
        if (sectorIdSelected) {
            listAllAlarms(sectorIdSelected);
        }
    }, [colorAlarm, sectorIdSelected])

    useEffect(() => {
        if (visible) {
            setSelectedSeeCheck(selectedAlarms?.seen)
        }
        if (!visible) {
            setObs('');
            setObsTemp(null);
            setOldObs(null);
        }
    }, [visible])

    useEffect(() => {
        if (data_alarms) {
            setFinalList(data_alarms.filter((note) => note.removedByUser === false))
        }
    }, [cleanFilter])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const listAllAlarms = async () => {
        if (sectorIdSelected) {
            const payload = {
                clientId: clientIdSelected,
                sectorId: sectorIdSelected,
                ignoreDeleted: true,
            }

            try {
                const res = await list_alarms_by_sector(payload, token);
                const filteredAlarms = res.data.filter((note) => note.showToUser === true);
                setFinalList(filteredAlarms.filter((note) => note.removedByUser === false).sort((a, b) => new Date(a.alarmNoteDate) - new Date(b.alarmNoteDate)));
                setDataAlarms(filteredAlarms);
                callColorAlarm(filteredAlarms.filter((note) => note.removedByUser === false));
                // setFinalList(res.data);
                // setDataAlarms(res.data);
            } catch (error) {
                let message;

                if (error.response && error.response.data) {
                    message = error.response.data.message;
                } else {
                    message = error.message;
                }

                if (error.response) {
                    toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "error_attention" }), detail: message, life: 3000 });
                } 
                // else if (error.request) {
                //     const message = error.message;
                //     toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "error_attention" }), detail: message, life: 3000 });
                // }
            }
        }
    }

    const callColorAlarm = (array) => {
        const results = [];

        for (const obj of array) {
            if (!obj.seen) {
                results.push(false);
            } else {
                results.push(true);
            }
        }

        if (results.includes(false)) {
            setColorAlarm(false);
        } else {
            setColorAlarm(true);
        }
    };

    const upDateAlarm = async (aux) => {
        try {
            await update_alarms(aux);
            callColorAlarm(finalList);
            toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "success_update_alarms_summary" }), life: 3000 });
            listAllAlarms();            
            handleVerifyButtonClick(aux);
            setShowMsgModal(true);
            setMsgModal(intl.formatMessage({ id: "success_update_alarms_summary" }));

        } catch (error) {
            if (error.response) {
                const message = error.response.data.message;
                toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "error_update_alarms_summary" }), detail: message, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "error_update_alarms_summary" }), detail: message, life: 3000 });
            }
            setShowMsgModal(true);
            setMsgModal(intl.formatMessage({ id: "error_update_alarms_summary" }));
        } finally {
            setTimeout(() => {
                setShowMsgModal(false);
            }, 3000);
            setTimeout(() => {
                setObs('');
            }, 1000);
        }
    }

    const onProductSelect = (e) => {
        setSelectedAlarms(e);
    };

    const handleSeen = (state, data) => {
        let aux = data;
        aux.seen = state;
        setSelectedSeeCheck(state);
        upDateAlarm(aux);
    }

    const handleVerifyButtonClick = async (data) => {
        const { clientId, alarmNoteId, clientObs } = data;
    
        try {
            const response = await note_by_id_alarms({
                clientId,
                alarmNoteId,
                ignoreDeleted: true,
            });
    
            if (response?.data) {
                const updatedData = {
                    ...data,
                    alarmNoteImg: response.data.alarmNoteImg,
                };
                const verify = Array.isArray(clientObs) 
                ? clientObs 
                : [{
                    userId: "",
                    userName: "",
                    obs: clientObs,
                    date: "xx-xx-xxxx"
                }];
                
                setSelectedAlarms(updatedData);
                setOldObs(verify);
                setObsTemp(verify);
                setVisible(true);
            } else {
                console.error('No data found in response');
            }
        } catch (error) {
            console.error('Error fetching alarm note:', error);
        }
    };
    

    const handleFilterButtonClickSee = () => {
        setSeeCheck(!seeCheck);
        filterAlarms(!seeCheck, unseeCheck);
    };

    const handleFilterButtonClickUnsee = () => {
        setUnseeCheck(!unseeCheck);
        filterAlarms(seeCheck, !unseeCheck);
    };

    const filterAlarms = (showSeen, showUnseen) => {
        const filteredAlarms = data_alarms.filter(alarm => {
            if (showSeen && showUnseen && alarm.removedByUser === false) {
                return true;
            }
            if (showSeen && alarm.removedByUser === false) {
                return alarm.seen;
            }
            if (showUnseen && alarm.removedByUser === false) {
                return !alarm.seen;
            }
            return false;
        });

        setFinalList(filteredAlarms.sort((a, b) => new Date(a.alarmNoteDate) - new Date(b.alarmNoteDate)));
    };

    const handleFilterButtonFile = () => {
        setShowCondition(true);
        const data = data_alarms.filter((alarm) => alarm.removedByUser === true).sort((a, b) => new Date(a.alarmNoteDate) - new Date(b.alarmNoteDate));
        setFinalList(data);
    };

    const handleCleanFilterButtonClick = () => {
        setShowCondition(false);
        setCleanFilter(!cleanFilter);
        setSeeCheck(true);
        setUnseeCheck(true);
    };

    const HandleRemove = (data) => {
        setConfirm(true);
        setRemoved(data);
    }

    const HandleAddBack = (data) => {
        setConfirmAddBack(true);
        setAddBack(data);
    }

    const handleButtonYes = async () => {
        let aux = confirm ? removed : addBack;
        aux.removedByUser = confirm ? true : false;
        upDateAlarm(aux);
        setConfirm(false);
        setConfirmAddBack(false);
        setVisible(false);
        setShowCondition(false);
    }

    const handleButtonNo = () => {
        setConfirm(false);
        setConfirmAddBack(false);
    }

    const handleAlarmSeeChange = async (data, checkState) => {
        let aux = data;
        aux.seen = checkState;
        upDateAlarm(aux);
    };

    const handleSaveObs = (data) => {
        setSaveMsg(true);
        let aux = data;
        let arrObs = [{
            userId: user.user_id,
            userName: user.name,
            obs: obs,
            date: moment().format('DD-MM-YYYY')
        }];
        arrObs.push(...obsTemp);
        aux.clientObs = arrObs;
        if (arrObs[0].obs.length > 0) {
            upDateAlarm(data);
        }
    }

    const alarmsTemplate = (option) => {
        return (
            <AlarmCardList
                option={option}
                alarmSeeChange={handleAlarmSeeChange}
                remove={HandleRemove}
                addBack={HandleAddBack}
                verifyButtonClick={handleVerifyButtonClick}
            />
        )
    };

    const handleHide = () => {
        setVisible(false);
    };

    const handleTypeObs = (data) => {
        setObs(data);
    };

    return (
        <>
            {finalList && <div className="flex flex-column align-items-center gap-3">
                {!visible ? <Toast ref={toast} position="top-center" /> : <Toast ref={toast} position="center" style={{ display: 'none' }} />}
                <ConfirmModal show={confirm} onYesClick={handleButtonYes} onNoClick={handleButtonNo} onClose={handleButtonNo} message={intl.formatMessage({ id: "message_remove_alarm" })} />
                <ConfirmModal show={confirmAddBack} onYesClick={handleButtonYes} onNoClick={handleButtonNo} onClose={handleButtonNo} message={intl.formatMessage({ id: "message_add_back_alarm" })} />
                <CustomDropdown show={isOpen} onClose={() => setIsOpen(false)}>
                    <CustomToggle as={"a"} style={{ height: "auto" }} onClick={() => setIsOpen(!isOpen)}>
                        <Tooltip target=".custom-target-icon" />
                        <AlarmIcon data={colorAlarm?.toString()} className="fas fa-bell custom-target-icon"
                            data-pr-tooltip={intl.formatMessage({ id: "tooltip_alarms" })}
                            data-pr-position="left"
                        ><FaBell size={25} /></AlarmIcon>
                    </CustomToggle>
                    <CustomMenu>
                        <Dropdown.Header>
                            <ContainerCardOpt>
                                {showCondition && <>
                                    <Tooltip target=".custom-target-eraserr" />
                                    <AlarmCardIconReturn className="pi pi-arrow-circle-left custom-target-eraserr"
                                        data-pr-tooltip={intl.formatMessage({ id: "tooltip_clean" })}
                                        data-pr-position="left"
                                        onClick={handleCleanFilterButtonClick}
                                    ></AlarmCardIconReturn>
                                </>}
                                {!showCondition && <AlarmLabelCheckBox>
                                    <Checkbox
                                        value="see"
                                        checked={seeCheck}
                                        onChange={handleFilterButtonClickSee}
                                        style={{ transform: 'scale(0.8)' }}
                                    />
                                    <FormattedMessage id="tooltip_seen" />
                                </AlarmLabelCheckBox>}
                                {!showCondition && <AlarmLabelCheckBox>
                                    <Checkbox
                                        value="unsee"
                                        checked={unseeCheck}
                                        onChange={handleFilterButtonClickUnsee}
                                        style={{ transform: 'scale(0.8)' }}
                                    />
                                    <FormattedMessage id="tooltip_unseen" />
                                </AlarmLabelCheckBox>}
                                <AlarmCardIconFile className="pi pi-file custom-target-file"
                                    onClick={handleFilterButtonFile}
                                > <FormattedMessage id="tooltip_archive" /></AlarmCardIconFile>
                            </ContainerCardOpt>
                        </Dropdown.Header>
                        <CustomlistBox
                            value={alarms}
                            onChange={(e) => onProductSelect(e.value)}
                            options={finalList}
                            optionLabel="name"
                            itemTemplate={alarmsTemplate}
                            listStyle={{ maxHeight: '80vh' }}
                            emptyMessage={
                                <ContainerMsgNoAlarm>
                                    <FormattedMessage id="no_alarm_message" />
                                </ContainerMsgNoAlarm>
                            } />
                    </CustomMenu>
                </CustomDropdown>
                <div className="flex justify-content-center">
                    <AlarmDialogList
                        visible={visible}
                        selectedSeeCheck={selectedSeeCheck}
                        onHide={handleHide}
                        selectedAlarms={selectedAlarms}
                        seen={handleSeen}
                        remove={HandleRemove}
                        addBack={HandleAddBack}
                        textObs={obs}
                        historyObs={oldObs}
                        typeObs={handleTypeObs}
                        saveObs={handleSaveObs}
                        showMsg={showMsgModal}
                        textAlert={msgModal}
                    />
                </div>
            </div>}
        </>
    );
}
